label {
  margin: 1rem 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D9D9D9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 3px;
  bottom: 1px;
  background-color: #42C3AA;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checkbtn:checked+.slider {
  background-color: #42C3AA;
}

.checkbtn:focus+.slider {
  box-shadow: 0 0 1px #D9D9D9;
}

.checkbtn:checked+.slider:before {
  -webkit-transform: translateX(38px);
  -ms-transform: translateX(38px);
  transform: translateX(38px);
  background-color: #fff;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* admin */

.sliderTab {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D9D9D9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderTab:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 3px;
  bottom: 1px;
  background-color: #8854d0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checkbtn:checked+.sliderTab {
  background-color: #8854d0;
}

.checkbtn:focus+.sliderTab {
  box-shadow: 0 0 1px #D9D9D9;
}

.checkbtn:checked+.sliderTab:before {
  -webkit-transform: translateX(38px);
  -ms-transform: translateX(38px);
  transform: translateX(38px);
  background-color: #fff;
}

.sliderTab.round {
  border-radius: 34px;
}

.sliderTab.round:before {
  border-radius: 50%;
}