@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css?family=Roboto);

td {
  width: 23%;
}

.card-title {
  font-size: 1.25rem;
  font-weight: 500;
  font-family: Inter, sans-serif;
  margin-bottom: 5px;
}

.title {
  margin: 0px;
  font-family: Inter, sans-serif;
  line-height: 1.5;
  letter-spacing: 0.15px;
  color: rgba(58, 53, 65, 0.87);
  font-weight: 600;
  font-size: 0.875rem;
}

.sub-title {
  margin: 0px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.4px;
  color: rgba(58, 53, 65, 0.68);
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  color: transparent;
}

.avatar-parent {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
}

.content {
  display: block;
  transform: none;
}

.content1 {
  display: block;
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
}

.content:hover .content1 {
  background-color: white;
  transform: translateY(-134px);
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 1s;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito+Sans:wght@400;700&family=Oswald:wght@300&family=Poppins:wght@300;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

.btn {
  top: 0;
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #42c3aa;
  border-radius: 30px;
  transition: 0.5s;
  color: #fff;
}

.active {
  left: 0px;
}

.inactive {
  left: 50%;
}

input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

ol li {
  list-style-type: decimal;
  display: list-item;
}

.clinput + .clbel {
  transition: 0.5s;
}

.clinput:hover + .clbel,
.clinput:focus + .clbel {
  color: #00cbb5;
  top: -36px;
  transition: 0.5s;
}

.clinput + .clbal {
  transition: 0.5s;
}

.clinput:hover + .clbal,
.clinput:focus + .clbal {
  color: #9b1fe9;
  top: -36px;
  transition: 0.5s;
}

.disabled:disabled {
  background-color: grey;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrDateDisplayWrapper {
  display: none;
}

@media all and (min-width: 1800px) {
  #chart {
    width: 24vw;
    margin: 35px auto;
  }

  #firstData {
    width: 24vw;
  }

  #secondData {
    width: 500px;
  }

  #analytics {
    width: 900px;
  }
}

#analytics .apexcharts-toolbar {
  display: none;
}

#chart .apexcharts-toolbar {
  display: none;
}

.d-block {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 9999999999;
}
